import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes as Switch } from "react-router-dom";
import "../i18n";
import PATHS, { routes } from "./routes";

const Routes = () => {
    const token = localStorage.getItem('token');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const listener = onAuthStateChanged(auth, async (user) => {
            setIsAuthenticated(!!user);
        });
        return () => {
            listener();
        };
    }, []);

    return <BrowserRouter>
        <Switch>
            {routes?.map((route, index) => {
                return <Route
                    key={index}
                    path={route.path}
                    element={!route.isPrivate ? route.element : <PrivateRoute
                        token={token}>
                        {route.element}
                    </PrivateRoute>}
                />
            })}
            <Route
                path="*"
                element={<Navigate to={PATHS.HOME} replace />}
            />
        </Switch>
    </BrowserRouter>
}

const PrivateRoute = ({ ...rest }) => {
    return !rest.token ? <Navigate to={PATHS.LOGIN} replace /> : rest.children;
}

export default Routes;