import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "./routes";
import { theme } from "./theme";
import Fonts from "../src/components/display/Fonts";
import { Suspense } from "react";
import Loader from "./components/status/Loader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
