import { Text, TextProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const LogoText: React.FC<PropsWithChildren<TextProps>> = ({children, ...rest}) => <Text 
  fontFamily="Morganite Medium" 
  fontSize="4xl" 
  textTransform="uppercase" 
  letterSpacing="0.5rem" 
  w="fit-content"
  {...rest}
>{children}</Text>;

export default LogoText;