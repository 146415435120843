import { Box, Button, FormControl, Image, Input, InputProps, Text, VStack } from "@chakra-ui/react"
import { useState, useRef } from 'react'
import { storage } from "../../../firebase";
import useDragAndDrop from "./hooks";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { DASHBOARD } from "../../../queries/dashboard/keys";

const FileInput = ({ getFileSrc, ...rest }: { getFileSrc: Function } & InputProps) => {
    const [imgData, setImgData] = useState<string | ArrayBuffer | null>('');
    const [file, setFile] = useState<File | null>();
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const inputRef = useRef(null);
    const {
        dragOver,
        setDragOver,
        onDragOver,
        onDragLeave,
        setFileDropError,
    } = useDragAndDrop();

    const handleUpload = async (file: any) => {
        const storageRef = ref(storage, `${DASHBOARD.NEWS_KEY}/${file.name}`);
        uploadBytes(storageRef, file).then((_) => {
            getDownloadURL(storageRef).then(downloadUrl => getFileSrc(downloadUrl));
        });
    };

    const onChangePicture = (e: any) => {
        if (e) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader?.result);
                handleUpload(e)
            });
            reader.readAsDataURL(e);
        }
    }

    const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();

        setDragOver(false);

        const selectedFile = e?.dataTransfer?.files[0];
        onChangePicture(selectedFile)

        if (selectedFile.type.split("/")[0] !== "image") {
            return setFileDropError("Please provide an image file to upload!");
        }

        setFile(selectedFile);
    };

    const fileSelect = (e: any) => {
        let selectedFile = e.target.files[0];
        onChangePicture(selectedFile)
        setFile(selectedFile);
        setFileDropError("");
    };

    return <Box {...rest}>
        <FormControl
            htmlFor="file"
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            //   @ts-ignore
            onDrop={onDrop}
            borderWidth="2px"
            borderColor="gray.300"
            borderStyle={dragOver ? "dashed" : "solid"}
            w="full"
            h="155px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onMouseOver={() => !!file && setShowDelete(true)}
            onMouseOut={() => !!file && setShowDelete(false)}
        >
            {file && <Box bgColor="gray.50" w="full" h="full" overflow="hidden">
                <Image
                    src={imgData?.toString() || ''}
                    objectFit='cover'
                    w="full"
                    alt="File image"
                    loading="lazy"
                />
            </Box>}
            {!file && (
                <Text as="h1">
                    {!dragOver ? "Haz click o arrasta tu imagen..." : "Arrastra aqui..."}
                </Text>
            )}
            <Input {...rest} onChange={fileSelect} w="full" pos="absolute" h="full" opacity={0} ref={inputRef} />
            <VStack
                display={showDelete ? 'flex' : 'none'}
                pos="absolute"
                justifyContent="center"
                w="full"
                h="full"
                bg="rgb(100,100,100, .5)"
            >
                <Button colorScheme="red" size="xs" onClick={() => {
                    setImgData('');
                    setFile(null)
                    setShowDelete(false)
                    // @ts-ignore
                    inputRef.current.value = null;
                }}>Limpiar</Button>
            </VStack>
        </FormControl>
    </Box>
}

export default FileInput;