import { Stack } from "@chakra-ui/react";
import {PrivateHeader} from "../components/layouts/Header";

export const withDefaultLayout = (WrappedComponent: React.ComponentType) => {
  const DefaultLayout: React.FC<{}> = () => (
    <Stack h="100vh" position="relative">
      <PrivateHeader />
      <main>
        <WrappedComponent />
      </main>
    </Stack>
  );

  return DefaultLayout;
};