import { colors } from "./colors";

export const breakpoints = {
  sm: "480px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};
  
export const styles = {
  global: {
    "html, body": {
      color: colors.terciaryColor,
      "&::-webkit-scrollbar": {
        width: "12px",
        backgroundColor: "inherit",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.primaryColor,
      },
    },
    "&::selection": {
      color: colors.terciaryColor,
      background: colors.primaryLightColor,
    },
    "&::-moz-selection": {
      color: colors.terciaryColor,
      background: colors.primaryLightColor,
    },
  },
};