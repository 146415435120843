import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Grid, GridItem, GridProps, HStack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SaveIcon from "../../../../assets/icons/saveIcon";
import { useToaster } from "../../../../components/Toast";
import DeleteButton from "../../../../components/form/DeleteButton";
import Input from "../../../../components/form/Input";
import PrimaryIconButton from "../../../../components/form/PrimaryIconButton";
import {
  useCreateData,
  useUpdateData,
} from "../../../../queries/dashboard/hooks";
import { DASHBOARD } from "../../../../queries/dashboard/keys";
import { calculateDiscount } from "../../../../utils";
import { useTranslate } from "../../../../utils/hooks";
import { GRID_ITEMS_PADDING, GRID_PARENT_GAP, GRID_PARENT_SIZE, INPUTS } from "./constants";
import { FormValues, schema } from "./validations";

type ServiceFormType = {
  isEdit?: boolean;
    data?: any;
    id?: number;
    rowIndexEnabled?: string,
    setRowIndexEnabled?: Function
} & GridProps

const ServiceForm = ({
  isEdit = false,
  data,
  id,
  rowIndexEnabled,
  setRowIndexEnabled,
  ...wrapperProps
}: ServiceFormType) => {
  const { translate } = useTranslate();
  const { mutate, isSuccess } = useCreateData(DASHBOARD.SERVICE_KEY);
  const { update, isSuccess: isSuccessUpdated } = useUpdateData(DASHBOARD.SERVICE_KEY);
  const { toast } = useToaster();

  console.log(isSuccessUpdated, isSuccess);
  

  useEffect(() => {
    (!!isSuccess || !!isSuccessUpdated) && toast.success(translate("dashboard.services.createdService"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessUpdated]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      originalPrice: null,
      ...data,
    },
  });

  useEffect(() => {
    reset({
      originalPrice: 0,
      discountedPrice: 0,
      ...data,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmitService = () => {
    if (isEdit) {
      update(data.id, watch());
      setRowIndexEnabled && setRowIndexEnabled(undefined)!;
    } else {
      mutate(watch());
      reset();
    }
  };

  const discount = calculateDiscount( {
    decimals:2 ,
    discountedPrice: watch("discountedPrice"),
    originalPrice:watch("originalPrice"), 
  });

  return (
    <Grid
      as="form"
      w="100%"
      templateColumns={GRID_PARENT_SIZE}
      gap={GRID_PARENT_GAP}
      paddingLeft={0}
      paddingRight={0}
      bgColor={!id || (id && !(id % 2)) ? "white" : "white"}
      onSubmit={handleSubmit(handleSubmitService)}
      {...wrapperProps}
    >
      {INPUTS?.map(({ name, required: _, placeholder, ...rest }, index) => (
        <GridItem key={index} w="100%" colSpan={rest.colSpan} rowSpan={2} padding={GRID_ITEMS_PADDING}>
          <Input
            isDisabled={rowIndexEnabled !== id}
            size="sm"
            // @ts-ignore
            register={register(name)}
            // @ts-ignore
            value={rest.type === "number" ? String(watch(name)).replace(/^0+/, ""): watch(name) || ""}
            {...rest}
            name={name}
            placeholder={translate(`dashboard.services.${placeholder}`)}
            // @ts-ignore
            isInvalid={errors?.[name]}
            {...(name === "discountedPrice"
              ? {
                min: 0,
                max: +watch("originalPrice"),
              }
              : {})}
            {...(name === "originalPrice" ? { min: 0 } : {})}
          />
          {/* @ts-ignore  */}
          {errors?.[name] && (
            <Text fontSize="10px" color="red.500" mt="5px" fontWeight="bold">
              {/* @ts-ignore  */}
              {translate(errors?.[name]?.message)}
            </Text>
          )}
        </GridItem>
      ))}
      <GridItem padding={GRID_ITEMS_PADDING}>
        <Text align="right" fontWeight="bold" color="red.500" w="100%">
          {`${discount} %`}
        </Text>
      </GridItem>

      <GridItem display="flex" justifyContent="flex-end" padding={GRID_ITEMS_PADDING}>
        {!isEdit ? (
          <HStack justifyContent="flex-start" gap="2" w="fit-content">
            <PrimaryIconButton
              type="submit"
              colorScheme="corporatePrimary"
              color="white"
              size="sm"
              aria-label={translate("add")}
              isRound
              icon={<AddIcon fill="white" />}
              title={translate("add")}
            />
          </HStack>
        ) : (
          <VStack justifyContent="flex-start" gap="1" w="fit-content">
            <PrimaryIconButton
              onClick={() => { setRowIndexEnabled && setRowIndexEnabled(id); }}
              colorScheme="corporatePrimary"
              color="white"
              aria-label={translate("edit")}
              isRound
              icon={<EditIcon fill="white" />}
              title={translate("edit")}
            />
            <PrimaryIconButton
              isDisabled={rowIndexEnabled !== id}
              type="submit"
              colorScheme="corporatePrimary"
              color="white"
              aria-label={translate("save")}
              isRound
              icon={<SaveIcon fill="white" />}
              title={translate("save")}
            />
            <DeleteButton
              aria-label={translate("delete")}
              dbKey={DASHBOARD.SERVICE_KEY}
              id={data.id}
              title={translate("delete")}
            />
          </VStack>
        )}
      </GridItem>
    </Grid>
  );
};

export default ServiceForm;
