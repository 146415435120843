import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, Grid, GridItem, HStack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SaveIcon from "../../../assets/icons/saveIcon";
import Input from "../../../components/form/Input";
import PrimaryButton from "../../../components/form/PrimaryButton";
import PrimaryIconButton from "../../../components/form/PrimaryIconButton";
import { useGeData, useUpdateData } from "../../../queries/dashboard/hooks";
import { DASHBOARD } from "../../../queries/dashboard/keys";
import { INPUTS, LabelTypes } from "./constants";
import { schema } from "./validations";
import { BussinessResponse } from "../../../definitions";
import { useTranslate } from "../../../utils/hooks";

const BusinessInformation = () => {
  const { data } = useGeData(DASHBOARD.BUSINESS_KEY);
  const { translate } = useTranslate();
  const bussiness = data[0] as BussinessResponse || [];
  const { update } = useUpdateData(DASHBOARD.BUSINESS_KEY);
  const [socialCounter, setSocialCounter] = useState(bussiness?.socialMedia?.length || 0);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<BussinessResponse>({
    resolver: yupResolver(schema),
    defaultValues: bussiness,
  });

  useEffect(() => {
    reset(bussiness);
    setSocialCounter(bussiness?.socialMedia?.length || 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <form onSubmit={handleSubmit(() => update(bussiness?.id!, watch()))}>
    <VStack position="relative" w="full" gap={5}>
      <Flex w="full" justifyContent="flex-end" alignItems="flex-end">
        <PrimaryButton
          position="absolute"
          top={0}
          zIndex={99}
          type="submit"
          aria-label="Guardar estado"
          leftIcon={<SaveIcon fill="white" />}
        >
          {translate("save")}
        </PrimaryButton>
      </Flex>
      <Box w="full" h="420px" overflowY="auto" padding="0 10px 20px 10px" mt="10px">
        <Grid
          w="full"
          templateColumns='repeat(14, 1fr)'
          gap={1}>
          <GridItem colSpan={2} >
            {(Object.keys(bussiness) as LabelTypes[]).map((key) => {
              return INPUTS[key] && <VStack h="40px" mb="8px" justifyContent="center" alignItems="flex-start" key={key}>
                <Text>{translate(`dashboard.companyInformation.${String(INPUTS[key]?.label)}`)}</Text>
              </VStack>;
            })}
            <Text>{translate("webSite.socialMedia")}</Text>
          </GridItem>
          <GridItem colSpan={12}>
            {Object.keys(bussiness).map((key) => {
              // @ts-ignore
              const input = INPUTS[key];
              return input && <Flex
                flexDir="column"
                key={key}
                h="40px"
                mb="8px"
                alignItems="flex-start"
              >
                <Input
                  // @ts-ignore
                  {...input}
                  // @ts-ignore
                  defaultValue={bussiness[key] || ""}
                  isInline={true}
                  label=""
                  placeholder={translate(`dashboard.companyInformation.${input?.placeholder}`)}                                    
                  // @ts-ignore
                  register={register(key)}
                  name={key}
                  // @ts-ignore
                  isInvalid={errors?.[key]}
                />
              </Flex>;
            })}
            <PrimaryIconButton
              colorScheme='corporatePrimary'
              color="white"
              size="sm"
              aria-label={"Añadir red social"}
              isRound
              icon={<AddIcon fill="white" />}
              title={translate("add")}
              onClick={() => setSocialCounter(socialCounter + 1)}
            />
            {Array(socialCounter).fill("*").map((_, i) => {
              return <HStack gap={3} mt="10px" key={`socialMedia[${i}]`}>
                <Input
                  type="text"
                  // @ts-ignore
                  defaultValue={bussiness[`socialMedia[${i}].name`] || ""}
                  isInline={true}
                  placeholder={translate("dashboard.companyInformation.writeSocialName")}
                  // @ts-ignore
                  register={register(`socialMedia[${i}].name`)}
                  name={`socialMedia[${i}]`}
                  // @ts-ignore
                  isInvalid={errors?.[`socialMedia[${i}].name`]}
                />
                <Input
                  type="text"
                  // @ts-ignore
                  defaultValue={bussiness[`socialMedia[${i}].url`] || ""}
                  isInline={true}
                  placeholder={translate("dashboard.companyInformation.writeSocialLink")}
                  // @ts-ignore
                  register={register(`socialMedia[${i}].url`)}
                  name={`socialMedia[${i}]`}
                  // @ts-ignore
                  isInvalid={errors?.[`socialMedia[${i}].url`]}
                />
                <PrimaryIconButton
                  size="sm"
                  variant="outline"
                  colorScheme="red"
                  aria-label={translate("delete")}
                  title={translate("delete")}
                  onClick={() => {
                    const elements = watch("socialMedia") || [];
                    elements.pop();
                    elements.length > 0 && (setValue("socialMedia", elements));
                    setSocialCounter(socialCounter - 1);
                  }}
                  icon={<DeleteIcon />}
                />
              </HStack>;
            })}
          </GridItem>
        </Grid>
      </Box>
    </VStack>
  </form>;
};

export default BusinessInformation;