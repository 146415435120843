import { useTranslation } from "react-i18next";

export const useTranslate = () => {
  const { t } = useTranslation();
  const translate = (key: string) => {
    console.log(key);
    
    return t(key as any);
  };
  return {translate};
};