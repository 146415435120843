import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { withDefaultLayout } from "../../HOC";
import { TABS_OPTIONS } from "./constants";
import React from "react";

const Dashboard = () => {
  return (
    <Tabs w="full" padding="2px">
      <TabList>
        {TABS_OPTIONS.map((tab) => <Tab key={tab.key}>{tab.title}</Tab>)}
      </TabList>

      <TabPanels>
        {TABS_OPTIONS.map((tab) => {
          return <TabPanel key={tab.key} padding="30px">
            {tab?.component && React.createElement(tab?.component)}
          </TabPanel>;
        })}
      </TabPanels>
    </Tabs>
  );
};

export default withDefaultLayout(Dashboard);
