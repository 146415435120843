import { CompanyInformationTypes } from "../../../definitions/translations";

export const INPUTS = {
  name: {
    label: "centerName" as CompanyInformationTypes,
    placeholder: "writeCenterName" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  email: {
    label: "email" as CompanyInformationTypes,
    placeholder: "writeCenterEmail" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  country: {
    label: "country" as CompanyInformationTypes,
    placeholder: "writeCountry" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  province: {
    label: "province" as CompanyInformationTypes,
    placeholder: "wrireProvince" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  municipality: {
    label: "municipality" as CompanyInformationTypes,
    placeholder: "writeMunicipality" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  mainPhone: {
    label: "mainPhone" as CompanyInformationTypes,
    placeholder: "writeMainPhone" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  secondaryPhone: {
    label: "secondaryPhone" as CompanyInformationTypes,
    placeholder: "writeSecondaryPhone" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  direction: {
    label: "direction" as CompanyInformationTypes,
    placeholder: "writeDirection" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
  coordinates: {
    label: "coordinates" as CompanyInformationTypes,
    placeholder: "writeCoordinates" as CompanyInformationTypes,
    type: "text",
    w: "100%",
  },
};
export type LabelTypes = keyof typeof INPUTS;
