import { useToast } from "@chakra-ui/react";

type ToastType = {
    state: "success" | "warning" | "error"
    message: string
}

export const useToaster = () => {
  const toast = useToast();
  const showToast = (state: ToastType["state"], message: ToastType["message"]) => toast({
    status: state,
    duration: 2000,
    isClosable: true,
    description: message,
    containerStyle: {
      color: "white",
    },
  });
  return {
    toast : {
      success : (message: ToastType["message"]) => showToast("success", message),
      warning : (message: ToastType["message"]) => showToast("warning", message),
      error : (message: ToastType["message"]) => showToast("error", message),
    },
  };
};
