import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { breakpoints } from "./styles";
import { fonts } from "./fonts";

export const theme = extendTheme({
  fonts,
  colors,
  breakpoints,
});
