import { Text, TextProps } from "@chakra-ui/react";

export const ELogo: React.FC<TextProps> = ({...rest}) => <Text 
  fontFamily="Morganite Medium" 
  color ="primaryColor"
  fontSize={100}
  border="1x solid white"
  {...rest}
>É</Text>;

export default ELogo;