import i18n  from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './translations/es.json';
export const defaultNS = 'es'

export const resources = {
  es: {
    es,
  },
} as const;

const i18next = i18n;
i18next.use(initReactI18next).init({
  lng: 'es',
  ns: ['es'],
  defaultNS,
  resources,
});

export default i18next;
