import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useGeData } from "../../../queries/dashboard/hooks";
import { DASHBOARD } from "../../../queries/dashboard/keys";
import ServiceForm from "./ServiceForm";
import { GRID_PARENT_GAP, GRID_PARENT_SIZE, INPUTS, LABELS } from "./ServiceForm/constants";
import { useTranslate } from "../../../utils/hooks";

const PricesForm = () => {
  const { data: services } = useGeData(DASHBOARD.SERVICE_KEY);
  const { translate } = useTranslate();
  const [ rowIndexEnabled, setRowIndexEnabled ] = useState<string|undefined>();

  return (
    <VStack w="full">
      {/* Labels */}
      <Grid w="100%" templateColumns={GRID_PARENT_SIZE} gap={GRID_PARENT_GAP}>
        {LABELS?.map((label, index) => {
          
          const fieldProps = INPUTS.find(input => input.key === label);
          return (
            <GridItem key={index} colSpan={fieldProps?.colSpan || 1}>
              <Text fontSize="xs" fontWeight="bold" textAlign="center" _after={{
                content: fieldProps?.required ? "'*'" : "''",
                color: "red.500",
                ml: "5px",
              }}>
                {translate(`dashboard.services.${label}`)}
              </Text>
            </GridItem>
          );
        })}
      </Grid>

      {/* Create new service */}
      <ServiceForm bg="white.200"/>

      {/* Edit service */}
      <Box w="full" h="55vh" overflowY="auto" >
        {services?.map((service, id) => {
          return (
            <ServiceForm
              id={id as any}
              key={service.id}
              isEdit
              data={service}
              rowIndexEnabled={rowIndexEnabled}
              setRowIndexEnabled={setRowIndexEnabled}
            />
          );
        })}
      </Box>
    </VStack>
  );
};

export default PricesForm;
