import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("requiredError"),
  email: yup.string().required("requiredError"),
  country: yup.string().required("requiredError"),
  province: yup.string().required("requiredError"),
  municipality: yup.string().required("requiredError"),
  mainPhone: yup.string().required("requiredError"),
  direction: yup.string().required("requiredError"),
  coordinates: yup.string().required("requiredError"),
});
