import { Box, Stack, useMediaQuery } from "@chakra-ui/react";
import LogoText from "../../layouts/Header/PublicHeader/components/LogoText";
import ELogo from "../ELogo";

const CompanyLogo = () => {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

  if(!isLargerThan480) {
    return <ELogo />;
  }

  return <>
    <Stack 
      justifyContent="center"
      alignItems="center"
      color="primaryDarkColor"
      border="4px solid"
      lineHeight={1} 
      boxSize={{
        base: "300px",
        md: "500px",
      }}
      borderRadius="full"
      background="rgba(255, 255, 255, 0.5)"
      // boxShadow=" 0 4px 30px rgba(0, 0, 0, 0.1);"
      backdropFilter="blur(5px)"
        
    >
      <Box w="265px">
        <LogoText fontSize="9xl" letterSpacing="1.2rem">
          Ph
          <LogoText fontSize="9xl" fontStyle="italic" as="span" mr="1rem">y</LogoText>
          sio
        </LogoText>
        <LogoText fontSize="9xl" letterSpacing="2.42rem">Élite</LogoText>
      </Box>
      <LogoText letterSpacing="0.6rem">Fisioterapia Avanzada</LogoText>
      <LogoText>Y Medicina Renegerativa</LogoText>
    </Stack>
  </>;
      
};

export default CompanyLogo;