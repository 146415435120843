import { Button, ButtonProps } from "@chakra-ui/react";

const PrimaryButton = ({
  children,
  ...rest
}: ButtonProps) => {
  return (
    <Button
      bgColor="primaryColor"
      _hover={{
        bgColor: "primaryDarkColor",
      }}
      color="white"
      letterSpacing={1.5}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
