type CalculateDiscountParams = {
    originalPrice: number,
    discountedPrice: number,
    decimals: number,
  }
  
  export const calculateDiscount = ({
    originalPrice,
    discountedPrice,
    decimals = 0,
  }: CalculateDiscountParams) => {
    if (!originalPrice || !discountedPrice) {
      return "0.00";
    }
    const diference = originalPrice - discountedPrice;
    return ((diference * 100) / originalPrice).toFixed(decimals);
  };
  
  type TimestampType = {
    "seconds": number,
    "nanoseconds": number
  };
  
  export const caculateBetweenDates = (timestamp: TimestampType) => {
  
    const startDate = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
  
    // Get the current date and time
    const now = new Date();
  
    // Find the difference between the two dates
    // @ts-ignore
    const difference = now - startDate;
  
    // Calculate days and hours
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60 ));
  
    const hasDays = days > 0;
    const hasHours = hours > 0;
    const hasMinutes = minutes > 0;
  
    let text = "Hace ";
  
    if (hasDays) {
      text = `${text} ${days} días ${hasHours && !hasMinutes ? `y ${hours} horas`: ""}`;
    }
  
    if (hasHours) {
      text = `${text} ${hours} horas ${hasMinutes ? `y ${minutes} minutos`: ""}`;
    }
  
    if (!hasDays && !hasHours && hasMinutes) {
      text += minutes + " minutos ";
    }
  
    if (!hasDays && !hasHours && !hasMinutes) {
      text = "Hace unos segundos";
    }
    return text;
  
  };
  