import { Icon } from "@chakra-ui/icons"
import { IconProps } from "@chakra-ui/react"

const LogoutIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
  </Icon>
)

export default LogoutIcon
