import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack, Icon, Stack,
  VStack, useMediaQuery
} from "@chakra-ui/react";
import { Link } from "react-scroll";
import { LINK } from "../../../../pages/WebSite/constants";
import ELogo from "../../../display/ELogo";
import LogoText from "./components/LogoText";

const PublicHeader = () => {

  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

  return <VStack 
    position="relative"
    w="full" 
    h={{ base: "95vh", sm: "100vh" }}
    alignItems={{ base: "center" }}
    bg="primaryLightColor"
    justifyContent="center" >
    <HStack
      zIndex={2}
    >
      <Stack 
        justifyContent="center"
        alignItems="center"
        color="primaryDarkColor"
        border="4px solid"
        lineHeight={1} 
        boxSize={{
          base: "300px",
          md: "600px",
        }}
        borderRadius="full"
        background="rgba(255, 255, 255, 0.5)"
        // boxShadow=" 0 4px 30px rgba(0, 0, 0, 0.1);"
        backdropFilter="blur(5px)"
        
      >
        {isLargerThan480 ? <>
          <Box w="265px">
            <LogoText fontSize="9xl" letterSpacing="1.2rem">
            Ph
              <LogoText fontSize="9xl" fontStyle="italic" as="span" mr="1rem">y</LogoText>
            sio
            </LogoText>
            <LogoText fontSize="9xl" letterSpacing="2.42rem">Élite</LogoText>
          </Box>
          <LogoText letterSpacing="0.6rem">Fisioterapia Avanzada</LogoText>
          <LogoText>Y Medicina Renegerativa</LogoText>
        </>: <ELogo />}
      </Stack>
      
    </HStack>

    <Box
      position="absolute"
      bottom={0}
      w="fit-content"
      h="fit-content"
      zIndex="2"
      transition="transition: all .8s"
      borderRadius={100}
      _hover={{
        transform: "scale(1.2)",
      }}
      cursor="pointer"
    >
      <Link
        to={LINK.PRESENTATION as string}
        href={`#${LINK.PRESENTATION as string}`}
        spy={true}
        smooth={true}
        offset={-80}
        duration={800}
        aria-label="Presentation-anchor-link"
      >
        <Icon
          as={ChevronDownIcon}
          w="5em"
          h="5em"
          color="primaryColor"
        />
      </Link>
    </Box>
  </VStack>;
};

export default PublicHeader;
