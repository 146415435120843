import {
  Button,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LogoutIcon from "../../../../assets/icons/logoutIcon";
import { logout } from "../../../../firebase";
import { useGetMe } from "../../../../queries/common/useAuth";
  
const PrivateHeader = () => {
  const { t } = useTranslation();
  const {data: user} = useGetMe();
  
  return (
    <HStack
      display={user && Object.keys(user).length ? "flex" : "none"}
      w="full"
      alignItems="center"
      justifyContent="space-between"
      bgColor="primaryColor"
      padding="10px"
    >
      <HStack alignItems="flex-end">
        <Image
          src={user?.photoURL || ""}
          w="60px"
          h="60px"
          borderRadius={100}
          alt="Center image"
        />
        <Text
          fontSize="18px"
          fontWeight="semibold"
          color="white"
        >
          {user?.displayName}
        </Text>
      </HStack>
      <Button 
        colorScheme="whiteColor"
        color="terciaryColor"
        size="sm" 
        leftIcon={<LogoutIcon />}
        onClick={logout}>
        {t("logout")}
      </Button>
    </HStack>
  
  );
};
  
export default PrivateHeader;
  