import * as yup from "yup";

export type FormValues = {
    name: string;
    descripion: string;
    originalPrice: number;
    discountedPrice: number;
};

export const schema = yup.object().shape({
  name: yup.string().required("requiredError"),
  originalPrice: yup
    .number()
    .required("requiredError")
    .typeError("requiredError"),
});
