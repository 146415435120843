const PRIMARY_LIGHT_COLOR = "#fcf6e8";
const PRIMARY_COLOR = "#1f1f1f";
const PRIMARY_DARK_COLOR = "#212d40";

const SECONDARY_COLOR = "#d3b984";
const TERCIARY_COLOR = "#666666";

export const colors = {
  primaryLightColor: PRIMARY_LIGHT_COLOR,
  primaryColor: PRIMARY_COLOR,
  primaryDarkColor: PRIMARY_DARK_COLOR,
  
  secondaryColor: SECONDARY_COLOR,
  terciaryColor: TERCIARY_COLOR,
  
  blueTerciary: "#5e7999",
  
  dangerColor: "red",
  
  whiteColor: {
    500: "#fbfcfc",
  },
};