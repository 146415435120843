import { IconButton, IconButtonProps } from "@chakra-ui/react";

const PrimaryIconButton = ({
  colorScheme,
  variant,
  ...rest
}: IconButtonProps) => {
  return (
    <IconButton
      bgColor={variant === "outline" ? "inherit" : "primaryColor"}
      color={`${colorScheme}.400`}
      _hover={{
        bgColor: "primaryLightColor",
      }}
      size="sm"
      isRound
      borderWidth="1px"
      borderColor={"primaryDarkColor"}
      {...rest}
    />
  );
};

export default PrimaryIconButton;
