import { Global } from "@emotion/react";

const Fonts = () => <Global
  styles={`
        // Logo Fonts
        @font-face {
          font-family: Morganite Medium;
          src: url('/fonts/assets/Morganite/Morganite-Medium.ttf') format('woff');
        }
        
        // Title fonts
        @font-face {
          font-family: Oswald;
          font-weight: 400;
          src: url('/fonts/assets/Oswald/Oswald.ttf') format('woff');
        }

        // Body fonts
        @font-face {
          font-family: Roboto Medium;
          src: url('/fonts/assets/Roboto/Roboto-Medium.ttf') format('woff');
        }

      `} />;

export default Fonts;