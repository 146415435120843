import {
    BoxProps, Checkbox,
    CheckboxProps, Flex, Input as ChakraInput,
    InputProps as ChakraInputProps, Radio, Text, Textarea,
    TextareaProps, TextProps
} from "@chakra-ui/react";
import FileInput from "./FileInput";

type InputProps = {
    label?: string,
    labelProps?: TextProps;
    wrapperProps?: BoxProps;
    getFileSrc?: Function;
    isInline?: boolean;
} & ChakraInputProps & TextareaProps & CheckboxProps

const InputTypes = ({ ...rest }) => {
    switch (rest.type) {
        case 'text':
        case 'email':
        case 'number':
        case 'password':
            return <ChakraInput w="full" {...rest} {...rest?.register} />;
        case 'file':
            return <FileInput getFileSrc={rest.getFileSrc} w="full" {...rest} />;
        case 'textarea':
            return <Textarea w="full" {...rest} {...rest?.register} />
        case 'checkBox':
            return <Checkbox {...rest}>{rest?.label}</Checkbox>
        case 'radio':
            return <Radio {...rest}>{rest?.label}</Radio>
        default:
            return null;
    }
}

const Input = ({
    labelProps,
    wrapperProps,
    isRequired,
    getFileSrc,
    isInline = false,
    ...rest
}: InputProps) => {
    return <Flex
        flexDirection={isInline ? 'row' : 'column'}
        alignItems={isInline ? 'center' : 'flex-start'}
        justifyContent={isInline ? 'center' : 'flex-start'}
        w="full"
        gap={2}
        {...wrapperProps}
    >
        {(rest?.label && !['checkBox'].includes(rest?.type!)) && <Text w="fit-content" _after={{
            content: isRequired ? "'*'" : 'none',
            ml: '5px',
            color: 'red.500'
        }} {...labelProps}>{rest?.label}</Text>}
        {InputTypes(getFileSrc ? { ...rest, getFileSrc: getFileSrc } : rest)}
    </Flex>
}

export default Input;