import { useFirestoreCollectionMutation, useFirestoreQuery } from '@react-query-firebase/firestore';
import { collection, doc, DocumentData, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';

export const useGeData = (key: string) => {
    const ref = collection(firestore, key);
    const query = useFirestoreQuery([key], ref, {
        subscribe: true,
    });
    const snapshot = query.data;
    const [data, setData] = useState<DocumentData[]>([]);

    useEffect(() => {
        const data = (snapshot?.docs || []).map((docSnapshot) => ({ id: docSnapshot.id, ...docSnapshot.data() }))
        setData(data);
    }, [snapshot])

    return {
        data
    }
}

export const useCreateData = (key: string) => {
    const ref = collection(firestore, key);
    return useFirestoreCollectionMutation(ref);
}

export const useUpdateData = (key: string) => {
    const serviceCollection = collection(firestore, key);
    return {
        ...useFirestoreCollectionMutation(serviceCollection),
        update: async (id: string, payload: any) => {
            const docRef = doc(serviceCollection, id);
            await setDoc(docRef, {...payload}, {
                merge: true
            });
        }
    }

}
