import { lazy } from 'react';
import { RouterProps } from "react-router-dom";
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

const WebSite = lazy(() => import('../pages/WebSite'));

type RoutesTypes = Omit<RouterProps, 'location' | 'navigator'> & {
    path: string,
    isExact: boolean,
    isPrivate: boolean,
    element: JSX.Element,
}

const PATHS = {
    HOME: '/' ,
    LOGIN: '/login' ,
    DASHBOARD: '/dashboard',
    BLOG_DETAIL_PATH: "blogdetail?id={id}",
};
export default PATHS;

export const routes: RoutesTypes[] = [
    {
        path: PATHS.HOME,
        isExact: true,
        isPrivate: false,
        element: <WebSite />,
    },
    {
        path: PATHS.LOGIN,
        isExact: true,
        isPrivate: false,
        element: <Login />,
    },
    {
        path: PATHS.DASHBOARD,
        isExact: true,
        isPrivate: true,
        element: <Dashboard />,
    },
]