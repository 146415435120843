import { VStack } from "@chakra-ui/react";
import EmotionBox from "../../animation/animatedBox";
import ELogo from "../../display/ELogo";

function Loader() {
  return (
    <VStack w="100%" h="100vh" alignItems="center" justifyContent="center">
      <VStack alignItems="center" justifyContent="center">
        <EmotionBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxSize="200px"
          border="3px solid"
          borderColor="primaryColor"
          borderRadius="full"
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            borderRadius: ["100%", "100%", "0%", "0%", "100%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          
        >
          <ELogo />
        </EmotionBox>
      </VStack>
    </VStack>
  );
}

export default Loader;
