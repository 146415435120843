import { ServicesTypes } from "../../../../definitions/translations";

export const LABELS: ServicesTypes[] = [
  "serviceName",
  "description",
  "originalPrice",
  "discountedPrice",
  "discount",
];

export const GRID_PARENT_SIZE = "repeat(10, 1fr)";
export const GRID_PARENT_GAP = 6;
export const GRID_ITEMS_PADDING = "8px";
export const INPUTS = [
  {
    key: "serviceName",
    colSpan:3,
    name: "name",
    placeholder: "writeServiceName" as ServicesTypes,
    type: "textarea",
    maxLength: 100,
    rows: 4,
    w: "100%",
    required: true,
  },
  {
    key: "description",
    colSpan: 3,
    name: "description",
    placeholder: "writeDescription" as ServicesTypes,
    type: "textarea",
    maxLength: 100,
    rows: 4,
    w: "100%",
  },
  {
    key: "originalPrice",
    colSpan: 1,
    name: "originalPrice",
    placeholder: "amountPlaceholder" as ServicesTypes,
    type: "number",
    w: "100%",
    step: 0.01,
    required: true,
  },
  {
    key: "discountedPrice",
    colSpan: 1,
    name: "discountedPrice",
    placeholder: "amountPlaceholder" as ServicesTypes,
    type: "number",
    w: "100%",
    step: 0.01,
  },
];

