import { Button, Text } from "@chakra-ui/react";
import GoogleIcon from "../../assets/icons/googleIcon";
import Background from "../../components/layouts/Background";
import { signInWithGoogle } from "../../firebase";
import { useGetToken } from "../../hooks";
import { useTranslate } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import PATHS from "../../routes/routes";

const Login = () => {
  const { translate } = useTranslate();
  const {hasToken} = useGetToken();
  const navigate = useNavigate();

  if(hasToken)  {
    navigate(PATHS.DASHBOARD)
  }

  return (
    <Background>
      <Text
        display="block"
        fontSize="1em"
        textTransform="uppercase"
        fontWeight="semibold"
        letterSpacing={1}
        mb="100px"
      >
        {translate("login.adminPanel")}
      </Text>
      <Button
        padding="10px 60px"
        onClick={signInWithGoogle}
        leftIcon={<GoogleIcon />}
      >
        {translate("login.signIn")}
      </Button> 
    </Background>
  );
};

export default Login;