import { DeleteIcon } from "@chakra-ui/icons";
import { IconButtonProps } from "@chakra-ui/react";
import { useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";
import { collection, doc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import PrimaryIconButton from "../PrimaryIconButton";

const DeleteButton = ({ dbKey, id, ...rest }: { dbKey: string, id: string } & IconButtonProps) => {
    const dbCollection = collection(firestore, dbKey);
    const ref = doc(dbCollection, id);
    const mutation = useFirestoreDocumentDeletion(ref);

    return <PrimaryIconButton
        variant='outline'
        colorScheme='red'
        size="sm"
        isRound
        icon={<DeleteIcon />}
        onClick={() => mutation.mutate()}
        {...rest}
    />
};

export default DeleteButton;