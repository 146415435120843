import { VStack } from "@chakra-ui/react";
import CompanyLogo from "../../display/LargeLogo";

const Background = ({children} : any) => {

  return (
    <VStack w="full" h="100vh" justifyContent="center" alignItems="center" gap="6" >
      <CompanyLogo />
      {children}
    </VStack>
  );
};

export default Background;